import React from 'react';
import PostPreview from '../global/PostPreview';
import translate from '~/utils/translate';

const RelatedPosts = ({ posts, heading }) => {
  return (
    <section className="bg-grey py-12 md:py-[152px]">
      <div className="container">
        <div className="max-w-[996px] mx-auto ">
          <h4 className="t-36">{heading || translate('Related Posts')}</h4>
          <div className="mt-[15px] bg-black bg-opacity-[0.24] h-px w-full mb-[42px]"></div>

          <div className="flex gap-[27px] flex-wrap md:flex-nowrap ">
            {posts?.map((post, i) => (
              <div key={`relatedPost${i}`} className="w-full md:w-1/3">
                <PostPreview {...post} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedPosts;
