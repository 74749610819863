import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Facebook, LinkedIn, Twitter } from '../elements/Icon';
import Image from '../elements/Image';
import translate from '~/utils/translate';

export const socialLinks = {
  twitter: (title) => {
    if (typeof window === 'undefined') return '';
    const url = encodeURIComponent(window.location.href);
    return `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
  },
  linkedin: (title) => {
    if (typeof window === 'undefined') return '';
    const url = encodeURIComponent(window.location.href);
    return `http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`;
  },
  facebook: (title) => {
    if (typeof window === 'undefined') return '';
    const url = encodeURIComponent(window.location.href);
    return `http://www.facebook.com/share.php?u=${url}`;
  },
};

const PostMasthead = ({ heading, image, layout, category, type, embed, formIframe, date, download, author, post }) => {
  let isInsights = layout === 'insight';
  let isGuides = layout === 'guide';
  let isEbook = layout === 'ebook';
  let isWebinar = layout === 'webinar';

  if (!author) author = null;

  return (
    <div
      className={`bg-darkNavy text-white relative ${!isWebinar ? ' pt-24 md:pt-48' : '  '} ${
        isEbook ? 'pb-12 md:pb-24' : ''
      }`}
    >
      {isInsights && image && (
        <div className="absolute h-[106px] md:h-[250px] w-full bottom-[-2px] md:bottom-0 right-0 left-0 bg-white" />
      )}

      <div className={` ${!isWebinar ? ' container' : ''}`}>
        {isEbook ? (
          <EbookMasthead
            category={category}
            heading={heading}
            image={image}
            author={author}
            type={type}
            embed={embed}
            formIframe={formIframe}
            date={date}
            post={post}
          />
        ) : isGuides ? (
          <GuidesMasthead category={category} author={author} heading={heading} image={image} date={date} post={post} />
        ) : isWebinar ? (
          <WebinarMasthead
            category={category}
            author={author}
            heading={heading}
            image={image}
            date={date}
            post={post}
          />
        ) : (
          <InsightsMasthead
            category={category}
            author={author}
            heading={heading}
            image={image}
            date={date}
            post={post}
          />
        )}
      </div>
    </div>
  );
};

export default PostMasthead;

export const InsightsMasthead = ({ category, heading, image, date, author, post }) => {
  if (!author) author = null;
  return (
    <>
      <div className="max-w-[792px] mx-auto">
        <h6 className="text-orange t-subheading mb-[18px]">{category}</h6>
        <h1 className="t-52">{heading}</h1>
        <DateAndShare image={image} title={heading} date={date} post={post} author={author} />
      </div>
      <div className="mt-[62px] max-w-[993px] mx-auto">
        <div className="rounded-[15px] overflow-hidden ">
          <Image image={image} className={`rounded-[15px] overflow-hidden transform transform-cpu `} />
        </div>
      </div>
    </>
  );
};

export const GuidesMasthead = ({ category, author, heading, image, date, post }) => {
  return (
    <>
      <div className="md:flex items-center justify-between pb-[94px] gap-x-12">
        <Image
          image={image}
          className={`w-full h-full max-w-[585px] rounded-[15px] overflow-hidden transform transform-cpu`}
        />

        <div className={`md:max-w-[510px] mt-12 md:mt-0`}>
          <PostMastheadText category={category} heading={heading} post={post} date={date} />
        </div>
      </div>
    </>
  );
};

export const EbookMasthead = ({ author, category, heading, image, type, embed, formIframe, date, post }) => {
  return (
    <div className="mx-auto overflow-hidden rounded-large">
      <div className="w-full md:flex">
        <div className=" w-full md:max-w-[50%] overflow-hidden bg-mediumNavy pt-12 lg:pt-[78px] px-4 md:px-6 lg:px-[78px] flex flex-col justify-between gap-12 ">
          <div className="max-w-[450px] text-center md:text-left mx-auto">
            <PostMastheadText category={category} heading={heading} post={post} date={date} />
          </div>
          <motion.div
            style={{ aspectRatio: '295/386' }}
            className={`w-full h-full max-w-[295px] mb-[-15%] mx-auto md:mx-0`}
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
          >
            <Image image={image} className={`w-full h-full max-w-[295px] mx-auto md:mx-0`} />
          </motion.div>
        </div>
        <div className=" w-full md:max-w-[50%] bg-white flex justify-center items-center px-6 py-12 md:p-12">
          <div className="max-w-[388px] w-full mx-auto">
            <div className="mb-3 md:mb-6 t-24 text-darkNavy">{translate('Get your copy')}</div>
            {formIframe && <div className="w-full" dangerouslySetInnerHTML={{ __html: formIframe }} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export const WebinarMasthead = ({ author, category, heading, image, date, post }) => {
  return (
    <div className="relative w-full h-full">
      <Image image={image} className={`w-full h-full pt-[16rem] sm:pt-[3rem] md:pt-0`} objectFit="cover" />

      <div className="absolute inset-0 flex items-end w-full h-full">
        <div className="container flex justify-start w-full">
          <div className=" mb-[45px] md:mb-[104px] mr-auto">
            <div className="max-w-[792px]">
              <PostMastheadText date={date} post={post} category={category} heading={heading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PostMastheadText = ({ category, heading, date, post }) => {
  return (
    <>
      <h6 className="text-orange t-subheading mb-[18px]">{category}</h6>
      <h1 className="!font-normal t-52">{heading}</h1>
      {category !== 'Ebooks' && <DateAndShare author={null} date={date} post={post} title={heading} />}
    </>
  );
};

export const DateAndShare = ({ image, title, heading, date, author, post }) => {
  if (author) {
    if (typeof document !== `undefined`) {
      var script = document.createElement('script');
      script.innerHTML =
        '{"@context": "https://schema.org","@type": "BlogPosting","headline": "' +
        title +
        '","editor": "' +
        author.node.firstName +
        ' ' +
        author.node.lastName +
        '","url":"' +
        window.location.href +
        '","datePublished": "' +
        date +
        '","dateCreated":  "' +
        date +
        '","dateModified":  "' +
        date +
        '","author": {"@type": "Person","image":"' +
        author.node.avatar.url +
        '","name": "' +
        author.node.firstName +
        ' ' +
        author.node.lastName +
        '"}}';
      script.setAttribute('type', 'application/ld+json');
      document.head.appendChild(script);
    }

    if (
      post.disableAuthorVisibility != null &&
      typeof post.disableAuthorVisibility.disableAuthorFeature !== `undefined` &&
      post.disableAuthorVisibility.disableAuthorFeature == true
    ) {
      var showAuthor = false;
    } else {
      var showAuthor = true;
    }

    var authURL = '/' + author.node.slug;

    if (showAuthor) {
      return (
        <div className="mt-[22px] flex t-20 items-center">
          <div className="flex items-center flexmobwrap">
            <div className="flex items-center opacity-80 itemflexmob">
              <a href={authURL} className="flex gap-4 authbit">
                <div>
                  <Image
                    image={author.node.avatar.url}
                    className={`overflow-hidden rounded-[50px] avapic transform transform-cpu `}
                  />
                </div>
                {translate('Written by')}: {author.node.firstName} {author.node.lastName}
              </a>{' '}
              <span className="px-[14px]">•</span>
              <div> {date}</div> <span className="px-[14px]">•</span>
              <div>{translate('Share')} </div>
              <div className="flex items-center space-x-[10px] pl-[14px] soccylist ml-4 soccylist-mobile">
                <a href={socialLinks.linkedin(heading)}>
                  <LinkedIn />
                </a>
                <a href={socialLinks.facebook(heading)}>
                  <Facebook />
                </a>
                <a href={socialLinks.twitter(heading)}>
                  <Twitter />
                </a>
              </div>
            </div>
            <div className="flex items-center space-x-[10px] pl-[14px] soccylist soccylist-desktop">
              <a href={socialLinks.linkedin(heading)}>
                <LinkedIn />
              </a>
              <a href={socialLinks.facebook(heading)}>
                <Facebook />
              </a>
              <a href={socialLinks.twitter(heading)}>
                <Twitter />
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-[22px] flex t-20 items-center">
          <div className="flex items-center flexmobwrap">
            <div className="flex items-center opacity-80 itemflexmob">
              <div> {date}</div> <span className="px-[14px]">•</span>
              <div>{translate('Share')} </div>
            </div>
            <div className="flex items-center space-x-[10px] pl-[14px] soccylist">
              <a href={socialLinks.linkedin(heading)}>
                <LinkedIn />
              </a>
              <a href={socialLinks.facebook(heading)}>
                <Facebook />
              </a>
              <a href={socialLinks.twitter(heading)}>
                <Twitter />
              </a>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="mt-[22px] flex t-20 items-center">
        <div className="flex items-center flexmobwrap">
          <div className="flex items-center opacity-80 itemflexmob">
            <div> {date}</div> <span className="px-[14px]">•</span>
            <span className="px-[14px]">•</span> <div>{translate('Share')} </div>
          </div>
          <div className="flex items-center space-x-[10px] pl-[14px] soccylist">
            <a href={socialLinks.linkedin(heading)}>
              <LinkedIn />
            </a>
            <a href={socialLinks.facebook(heading)}>
              <Facebook />
            </a>
            <a href={socialLinks.twitter(heading)}>
              <Twitter />
            </a>
          </div>
        </div>
      </div>
    );
  }
};
